import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createModal } from "react-modal-promise";
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Button from 'components/Button/Button';
import TextInput from 'components/TextInput/TextInput';


const useStyles = makeStyles((theme) => ({
	header: {
		marginBottom: theme.spacing(1),
		width: '100%',
		textAlign: 'center',
	},
	textInput: {
		marginBottom: theme.spacing(1),
	},
	form: {
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	button: {
		width: '100%',
		height: 42,
		marginTop: theme.spacing(2),
	},
	loading: {
		marginLeft: theme.spacing(1),
	},
	error: {
		minHeight: theme.spacing(2.5),
		color: 'red',
		textAlign: 'center',
	},
	dialogPaper: {
		...theme.component.dialogPaper,
	},
	darkButton: {
		background: theme.palette.primary.dark,
		color: 'rgba(245,245,245,1)'
	},
	actions: {
		display: 'flex',
		justifyContent: 'space-between',
	}
}));

function FirstLoginDialog(props) {
	
	const { onClose, onResolve, open, title } = props;
	const classes = useStyles();
	const theme = useTheme();
	const { t } = useTranslation('common');
	
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	
	const submit = () => onResolve(newPassword);
	const cancel = () => onResolve();
	
	const handleClose = () => {
		onClose();
	};
	
	const upSm = useMediaQuery(theme.breakpoints.up('sm'));
	
	return (
		<Dialog
			onClose={handleClose}
			aria-labelledby="simple-dialog-title"
			open={open}
			classes={{ paper: classes.dialogPaper }}
			disableBackdropClick
			fullScreen={!upSm}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle id="simple-dialog-title">{title}</DialogTitle>
			<DialogContent>
				<TextInput
					onChange={(e)=>setNewPassword(e.target.value)}
					required
					label={t('auth.password')}
					type="password"
					fullWidth={true}
					className={classes.textInput}
					/>
				<TextInput
					onChange={(e)=>setConfirmPassword(e.target.value)}
					required
					label={t('auth.password2')}
					type="password"
					fullWidth={true}
					className={classes.textInput}
				/>
			</DialogContent>
			<Divider />
			<DialogActions className={classes.actions}>
				<Button className={classes.darkButton} variant='outlined' onClick={cancel}>
					{t('auth.cancel')}
				</Button>
				<Button className={classes.darkButton} variant='outlined' disabled={!newPassword || newPassword !== confirmPassword} onClick={submit}>
					{t('auth.save')}
				</Button>
			</DialogActions>
		</Dialog>
		)
	}
	
	FirstLoginDialog.propTypes = {
		onClose: PropTypes.func,
		open: PropTypes.bool
	};
	
	const ModalFirstLoginDialog = createModal(FirstLoginDialog);
	export default ModalFirstLoginDialog;
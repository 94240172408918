import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';

import countryToFlag from 'util/countryToFlag';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
	},
	button: {
		fontSize: '2rem',
	}
}));

function LanguageSelect() {
	const classes = useStyles();
	const { i18n } = useTranslation();

	const handleChangeLanguage = lang => {
		i18n.changeLanguage(lang);
	}

	return (
		<div className={classes.root}>
			<Button
				className={classes.button}
				onClick={() => handleChangeLanguage('fi')}
			>
				{countryToFlag('FI')}
			</Button>
			<Button
				className={classes.button}
				onClick={() => handleChangeLanguage('en')}
			>
				{countryToFlag('US')}
			</Button>
			<Button
				className={classes.button}
				onClick={() => handleChangeLanguage('et')}
			>
				{countryToFlag('EE')}
			</Button>
			<Button
				className={classes.button}
				onClick={() => handleChangeLanguage('sv')}
			>
				{countryToFlag('SE')}
			</Button>
			<Button
				className={classes.button}
				onClick={() => handleChangeLanguage('pl')}
			>
				{countryToFlag('PL')}
			</Button>
			<Button
				className={classes.button}
				onClick={() => handleChangeLanguage('ro')}
			>
				{countryToFlag('RO')}
			</Button>
			<Button
				className={classes.button}
				onClick={() => handleChangeLanguage('ru')}
			>
				{countryToFlag('RU')}
			</Button>
		</div>
	)
}

export default LanguageSelect

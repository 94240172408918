import * as actionTypes from './actionTypes';

export const setEmail = ( value ) => {
    return {
        type: actionTypes.SET_EMAIL,
        val: value
    };
};

export const setPhone = ( value ) => {
    return {
        type: actionTypes.SET_PHONE,
        val: value
    };
};

export const setIsPhoneAccount = ( value ) => {
    return {
        type: actionTypes.SET_IS_PHONE_ACCOUNT,
        val: value
    };
};
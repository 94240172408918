import React, { useEffect } from 'react';
import * as Cookies from 'js-cookie';
import 'amazon-cognito-js';
import Config from '../../Config';
import PropTypes from 'prop-types';
import { CognitoUserPool, CognitoUser, CognitoUserSession, CognitoAccessToken, CognitoIdToken, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core/styles';

import LayoutSimpleGrid from 'components/Layout/LayoutSimpleGrid/LayoutSimpleGrid';
import Loading from 'components/Loading/Loading';


const styles = theme => ({
  loggingOut: {
    margin: theme.spacing(5),
  },
});

function Logout(props) {
  const { classes } = props;
  const { t } = useTranslation('common');

  useEffect(() => {
    logout();
  }, []);

  const logout = () => {
    const onLogoutSuccess = logoutSuccess;
    const username = decodeURIComponent(Cookies.get('username'));
    const refresh_token = decodeURIComponent(Cookies.get('refresh_token'));
    console.log(`Logging out user ${username}`);

    const userPool = new CognitoUserPool({
      UserPoolId: Config.COGNITO_USER_POOL_ID,
      ClientId: Config.COGNITO_CLIENT_ID
    });
    const userData = {
      Username: username,
      Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);

    if (!refresh_token) {
      console.log("No refresh token cookie");
      onLogoutSuccess();
      return;
    }
    cognitoUser.refreshSession(new CognitoRefreshToken({ RefreshToken: refresh_token }), (error, session) => {
      if (error) {
        if (error.code === "NotAuthorizedException") {
          console.log("Bad refresh token:", error.message);
          onLogoutSuccess();
          return;
        }
        console.log("refreshSession error", JSON.stringify(error));
        throw new Error("Error refreshing session");
      }

      const idToken = session.getIdToken().getJwtToken();
      const accessToken = session.getAccessToken().getJwtToken();
      const refreshToken = session.getRefreshToken().getToken();

      const sessionData = {
        IdToken: new CognitoIdToken({ IdToken: idToken }),
        AccessToken: new CognitoAccessToken({ AccessToken: accessToken }),
        RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken })
      };
      const userSession = new CognitoUserSession(sessionData);

      const cognitoUser = new CognitoUser(userData);
      cognitoUser.setSignInUserSession(userSession);

      cognitoUser.getSession(function (err, session) {
        console.log("Session", session);
        cognitoUser.signOut();
        onLogoutSuccess();
      });
    });
  }

  const logoutSuccess = () => {
    const attributes = `;path=/api/;secure;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = "access_key =" + attributes;
    document.cookie = "username =" + attributes;
    document.cookie = "refresh_token =" + attributes;
    let params = new URL(window.location.href).searchParams.toString() || '';
    if (params) params = `?${params}`;
    window.location.replace("/" + params);
  }

  return (
    <LayoutSimpleGrid>
      <Grid item sm={12}>
        <Typography variant="display1" className={classes.loggingOut}>
          {t('logout.logging_out')}
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <Loading />
      </Grid>
    </LayoutSimpleGrid>
  )
}

Logout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Logout);
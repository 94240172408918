import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Input from 'components/Input/Input';
import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core/styles';

import Loading from 'components/Loading/Loading';

import RightIcon from 'mdi-material-ui/ChevronRight';
import DownIcon from 'mdi-material-ui/ChevronDown';


const styles = theme => ({
  textField: {
    height: 45,
  },
	box: {
		width:"100%",
		marginTop:20,
		paddingBottom:20,
		textAlign:"center",
	},
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    width: '100%',
    height: 42,
  },
  headline: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  loading: {
    marginLeft: theme.spacing(1),
  },
  error: {
    minHeight: theme.spacing(2),
    color: 'red',
    textAlign: 'center',
  },
});

function RegistrationForm(props) {
  const { t } = useTranslation('common');
  const {
    classes, data, passwordHelper, password2Helper, error,
    loading, register, submitEnabled
  } = props;

	const companyInfoOk = data.name.value === "" || data.business_id.value === "";
	const [eBtn, eBtnSet] = useState(companyInfoOk);
	const showCompany = eBtn || companyInfoOk;
	const sender = data.sender ? data.sender.value : null;

  /**
   * Login when pressing enter.
   * @param {*} event 
   */
  function handleKeyPress(event) {
    if (event.charCode === 13 && submitEnabled) {
      event.preventDefault();
      event.stopPropagation();
      register();
    }
  }

  const renderInputs = (prefill) => (input) => {
    const RenderInput = (
      <Input
        className={{ input: classes.textField }}
        fullWidth={true}
        key={input[0]}
        label={input[1]}
        margin="normal"
        onChange={props.onChangeData(input[0])}
        readOnly={prefill}
        value={data[input[0]].value}
      />
    )
    return RenderInput;
  } 

	return (
    <form noValidate autoComplete="off" className={classes.form}>
      <Typography variant="h5" className={classes.headline}>
        {t('auth.register_user')}
        {sender && <div>
          <br/>
          Kutsuja: {sender.first_name} {sender.last_name}<br/>
          {sender.phone}<br/>
          {sender.email}
        </div>}
      </Typography>
      
      <Typography variant="body1" className={classes.error}>
        {error ? error : ''}
      </Typography>
      
      <Input value={data.first_name.value}
        onChange={props.onChangeData('first_name')}
        required={data.first_name.required}
        fullWidth={true}
        className={{ input: classes.textField }}
        error={data.first_name.error}
        margin="normal"
        onKeyPress={handleKeyPress}
        label={t('auth.first_name')}
      />

      <Input value={data.last_name.value}
        onChange={props.onChangeData('last_name')}
        required={data.last_name.required}
        fullWidth={true}
        className={{ input: classes.textField }}
        error={data.last_name.error}
        margin="normal"
        onKeyPress={handleKeyPress}
        label={t('auth.last_name')}
      />
      <Input
        className={{ input: classes.textField }}
        error={data.dateOfBirth.error}
        label={t('auth.date_of_birth')}
        fullWidth={true}
        margin="normal"
        max={data.dateOfBirth.max}
        min={data.dateOfBirth.min}
        name={t('auth.date_of_birth')}
        onChange={props.onChangeData('dateOfBirth')}
        onKeyPress={handleKeyPress}
        required={data.dateOfBirth.required}
        type="date"
        value={data.dateOfBirth.value}
      />
      {data.dateOfBirth.error &&
        <Typography variant="caption" className={classes.error}>
          {t('auth.date_of_Birth_requirements')}
        </Typography>
      }
      
      <Input value={data.taxNumber.value}
        onChange={props.onChangeData('taxNumber')}
        required={data.taxNumber.required}
        fullWidth={true}
        className={{ input: classes.textField }}
        error={data.taxNumber.error}
        margin="normal"
        onKeyPress={handleKeyPress}
        label={t('auth.taxNumber')}
      />

      <Input value={data.phone.value}
        name="username"
        type="phone"
        onChange={props.onChangeData('phone')}
        required={data.phone.required}
        fullWidth={true}
        className={{ input: classes.textField }}
        error={data.phone.error}
        margin="normal"
        onKeyPress={handleKeyPress}
        label={t('auth.phone_number')}
      />

      <div className={classes.box}>
        <Button
          onClick={()=>{eBtnSet(!eBtn)}}
          variant="contained" color="primary"
          className={classes.button}
          disabled={companyInfoOk}
        >
          {!companyInfoOk && <span>{data.name.value}</span>}
          {companyInfoOk && <span>{t('auth.employer')}</span>}
          {showCompany?<DownIcon/>:<RightIcon/>}
        </Button>

        {showCompany &&
          <div>
            <Input
              className={{ input: classes.textField }}
              fullWidth={true}
              error={data.business_id.error}
              label={t('auth.business_id')}
              margin="normal"
              onChange={props.onChangeBusinessId}
              onKeyPress={handleKeyPress}
              readOnly={false}
              required={data.business_id.required}
              value={data.business_id.value}
            />
            <Input
              className={{ input: classes.textField }}
              error={data.name.error}
              fullWidth={true}
              label={t('auth.company_name')}
              margin="normal"
              onChange={props.onChangeData('name')}
              onKeyPress={handleKeyPress}
              readOnly={data.prefill.value}
              required={data.name.required}
              value={data.name.value}
            />

            {[
              ["street1", t("contact.street_address")],
              ["street2", t("contact.street_address_row2")],
              ["co", t("contact.co")],
              ["postcode", t("contact.postcode")],
              ["city", t("contact.city")],
              ["country", t("contact.country")],
              ["explanation", t("contact.explanation")]
            ].map(renderInputs(data.prefill.value))}
        
            <br/>
        
            <Typography>
              {t('auth.contact_person')}
            </Typography>

            {[
              ["contact_first_name", t("contact.first_name")],
              ["contact_last_name", t("contact.last_name")],
              ["contact_street1", t("contact.street_address")],
              ["contact_street2", t("contact.street_address_row2")],
              ["contact_co", t("contact.co")],
              ["contact_postcode", t("contact.postcode")],
              ["contact_city", t("contact.city")],
              ["contact_country", t("contact.country")],
              ["contact_email", t("contact.email")],
              ["contact_phone", t("contact.phone")],

            ].map(renderInputs(data.prefill.value))}
          </div>
        }
      </div>

      <Input value={data.password.value}
        onChange={props.onChangeData('password')}
        required={data.password.required}
        error={data.password.error}
        label={t('auth.password')}
        type="password"
        fullWidth={true}
        className={{ input: classes.textField }}
        helpertext={passwordHelper(data.password.value,data.password.value)}
        onKeyPress={handleKeyPress}
        margin="normal"
      />

      <Typography variant="caption">
        {t('auth.password_requirements')}
      </Typography>
      
      <Input value={data.password2.value}
        onChange={props.onChangeData('password2')}
        required={data.password2.required}
        error={!props.isValid(data.password2)}
        label={t('auth.password2')}
        type="password"
        fullWidth={true}
        className={{ input: classes.textField }}
        helpertext={password2Helper(data.password.value,data.password2.value)}
        onKeyPress={handleKeyPress}
        margin="normal"
      />

      <Button
        onClick={register}
        variant="contained" color="primary"
        className={classes.button}
        disabled={!submitEnabled}
      >
        {t('auth.register')}
        {loading && <Loading className={classes.loading} />}
      </Button>
    </form>
  )
}

RegistrationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  error: PropTypes.string,
  isPhoneAccount: PropTypes.bool.isRequired,
	isValid:PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onChangeBusinessId: PropTypes.func.isRequired,
  onChangeData: PropTypes.func.isRequired,
  passwordHelper: PropTypes.func.isRequired,
  password2Helper: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  submitEnabled: PropTypes.bool.isRequired,
  togglePhoneAccount: PropTypes.func.isRequired,
};

export default withStyles(styles)(RegistrationForm)

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';

import { capitalize } from 'util/helpers';


const useStyles = makeStyles((theme) => ({
	root: {
		...theme.typography.fontPanton,
		borderRadius: '4px',
		boxSizing: 'border-box',
		color: props => props.outline ? theme.palette.text.secondary : theme.palette.textWhite.secondary,
		fontSize: '0.9rem',
		//lineHeight: 1.1,
		minHeight: theme.spacing(5),
		paddingBottom: theme.spacing(0.75),
		paddingLeft: theme.spacing(1.5),
		paddingRight: theme.spacing(1.5),
		paddingTop: theme.spacing(0.75) +2,
		textAlign: 'center',
		transition: theme.transitions.create(['box-shadow', 'color']),
		'&:hover': {
			//boxShadow: theme.shadows[1],
			color: theme.palette.textWhite.primary,
			'& $backface': {
				opacity: 0.1,
			},
		},
		'&:active': {
			boxShadow: theme.shadows[4],
		},
	},
	elevated: {
		boxShadow: theme.shadows[1],
	},
	backface: {
		backgroundColor: 'rgb(0,0,0)',
		bottom: 0,
		left: 0,
		right: 0,
		opacity: 0,
		position: 'absolute',
		top: 0,
		transition: theme.transitions.create('opacity'),
		zIndex: 0,
	},
	colorDefault: {
		backgroundColor: theme.palette.buttonPrimary,
	},
	colorPrimary: {
		backgroundColor: theme.palette.buttonPrimary,
	},
	colorSecondary: {
		backgroundColor: theme.palette.buttonSecondary,
	},
	colorMain: {
		backgroundColor: theme.palette.buttonMain,
	},
	colorDark: {
		backgroundColor: theme.palette.buttonDark,
	},
	colorDarker: {
		backgroundColor: theme.palette.buttonDarker,
	},
	colorLight: {
		backgroundColor: theme.palette.buttonLight,
	},
	colorLighter: {
		backgroundColor: theme.palette.buttonLighter,
	},
	colorDarkGrey: {
		backgroundColor: theme.palette.grey['800'],
	},
	colorGrey: {
		backgroundColor: theme.palette.grey['600'],
	},
	colorDanger: {
			backgroundColor: theme.palette.danger.main
	},
	colorTrash: {
			backgroundColor: theme.palette.trash.main
	},
	colorOutlineDefault: {
		borderColor: theme.palette.buttonPrimary,
	},
	colorOutlinePrimary: {
		borderColor: theme.palette.buttonPrimary,
	},
	colorOutlineSecondary: {
		borderColor: theme.palette.buttonSecondary,
	},
	colorOutlineMain: {
		borderColor: theme.palette.buttonMain,
	},
	colorOutlineDark: {
		borderColor: theme.palette.buttonDark,
	},
	colorOutlineDarker: {
		borderColor: theme.palette.buttonDarker,
	},
	colorOutlineLight: {
		borderColor: theme.palette.buttonLight,
	},
	colorOutlineLighter: {
		borderColor: theme.palette.buttonLighter,
	},
	colorOutlineDarkGrey: {
		borderColor: theme.palette.grey['800'],
	},
	colorOutlineGrey: {
		borderColor: theme.palette.grey['600'],
	},
	colorOutlineDanger: {
		borderColor: theme.palette.danger.main
	},
	colorOutlineTrash: {
		borderColor: theme.palette.trash.main
	},
	fullHeight: {
		height: '100%',
	},
	fullWidth: {
		width: '100%',
	},
	disabled: {
		opacity: 0.5,
	},
	outline: {
		borderStyle: 'solid',
		borderWidth: 1,
		//color: theme.palette.text.main,
	},
}));

const Button = React.forwardRef((props, ref) => {
	const classes = useStyles({ outline: props.outline });

	const {
		children,
		className: classNameProp,
		color,
		disabled,
		elevated,
		fullHeight,
		fullWidth,
		onClick,
		outline,
		...rootProps
	} = props;

	const className = clsx(
		classes.root,
    color && !outline && classes[`color${capitalize(color)}`],
		color && outline && classes[`colorOutline${capitalize(color)}`],
		outline && classes.outline,
		classNameProp,
		elevated && classes.elevated,
		fullWidth && classes.fullWidth,
		fullHeight && classes.fullHeight,
		disabled && classes.disabled
	);

	return (
		<ButtonBase
			{...rootProps}
			className={className}
			disabled={disabled}
			onClick={onClick}
			ref={ref}
		>
			<span className={classes.backface} />
			{children}
		</ButtonBase>
	)
})

Button.displayName = 'Button';

Button.propTypes = {
	className: PropTypes.string,
	classNameProp: PropTypes.string,
  children: PropTypes.node,
	color: PropTypes.string,
	disabled: PropTypes.bool,
	elevated: PropTypes.bool,
	fullHeight: PropTypes.bool,
	fullWidth: PropTypes.bool,
	onClick: PropTypes.func,
	outline: PropTypes.bool,
	rootProps: PropTypes.object,
};

export default Button;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  button: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  description: {
    textAlign: 'center',
    opacity: 0.8,
  }
});

function LoginForm(props) {
  const { classes } = props;
  const { t } = useTranslation('common');

  return (
    <div>
      <Typography variant="body1" className={classes.description}>
        {t('promo.product_description')}
      </Typography>
      {/*
      <Button
          onClick={() => {}}
          variant="contained" color="primary"
          data-testid="login"
          className={classes.button}>
          {t('promo.read_more')}
        </Button>
        */}
    </div>
  )
}


LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginForm)
export { LoginForm }
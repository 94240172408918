import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core/styles';

import ModalFactory from "react-modal-promise";


import Button from 'components/Button/Button';
import ButtonLoading from 'components/ButtonLoading/ButtonLoading';
import TextInput from 'components/TextInput/TextInput';

import FirstLoginDialog from '../FirstLoginDialog/FirstLoginDialog';

const styles = theme => ({
  header: {
    marginBottom: theme.spacing(1),
    width: '100%',
    textAlign: 'center',
  },
  textField: {
    height: 60,
  },
  form: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    width: '100%',
    height: 42,
    marginTop: theme.spacing(2),
  },
  loading: {
    marginLeft: theme.spacing(1),
  },
  error: {
    minHeight: theme.spacing(2.5),
    color: 'red',
    textAlign: 'center',
  },
});

const myRef = React.createRef();

function LoginForm(props) {
  //const [text, setText] = useState(+358 (O));
  const { classes, data, onLogin, loading, submitEnabled } = props;
  const { t } = useTranslation('common');

  const [dialogOpen, setDialogOpen] = useState(false);

  /* const handleClick = async () => {
    const new_password = await FirstLoginDialog({title: t('auth.give_new_password')});
    debugger;
  } */

  /*
	handlePhoneChange = (e) => {
		let numbers=e.target.value.replace(/\D/g,"");

		let cc=numbers.substring(0,3);
		let ac=numbers.substring(3,5);
		let pn=numbers.substring(5,numbers.length);

		let num="+"+cc+" (O)"+ac+" "+pn;

		setText(num.trim());
		props.dataChanged('username')("+"+numbers);
	}
  */

  /**
   * Login when pressing enter.
   * @param {*} event 
   */
  function handleKeyPress(event) {
    if (event.charCode === 13 && submitEnabled) {
      event.preventDefault();
      event.stopPropagation();
      onLogin();
    }
  }

  return (
    <form noValidate autoComplete="on" className={classes.form}>
      <Typography variant="h5" className={classes.header}>
        {t('scene.logging_in')}
      </Typography>
      <Typography variant="body1" className={classes.error}>
        {props.error ? props.error : ''}
      </Typography>

      <TextInput
        onChange={props.dataChanged('username')}
        required
        label={t('login.username')}
        type="email"
        data-testid="username-id"
        id="username-id"
        fullWidth={true}
        onKeyPress={handleKeyPress}
        margin="normal"
      />
      <TextInput
        onChange={props.dataChanged('password')}
        required
        label={t('auth.password')}
        type="password"
        data-testid="password-id"
        id="password-id"
        fullWidth={true}
        onKeyPress={handleKeyPress}
        margin="normal"
      />
      <Button
        className={classes.button}
        data-testid="login"
        disabled={!submitEnabled || loading}
        fullWidth
        onClick={props.onLogin}
        variant="contained" color="secondary"
      >
        {loading ? <ButtonLoading className={classes.loading} /> : t('auth.start')}
      </Button>
      {/* <Button
        onClick={handleClick}
        variant="contained" color="secondary"
        className={classes.button}
      >
        avaa
      </Button>
      <ModalFactory /> */}
    </form>
  )
}


LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  dataChanged: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  submitEnabled: PropTypes.bool.isRequired,
  setRegisterView: PropTypes.func.isRequired,
  setResetPasswordView: PropTypes.func.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool
};

export default withStyles(styles)(LoginForm)
export { LoginForm }

import * as actionTypes from '../actions/actionTypes';

const initialState = {
  route: '',
};

const setRoute = (state, action) => {
  return { ...state, route: action.val };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ROUTE: return setRoute(state, action);
    default: return state;
  }
};

export default reducer;

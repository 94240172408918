import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import LanguageSelect from 'components/LanguageSelect/LanguageSelect';
import LayoutSimpleGrid from 'components/Layout/LayoutSimpleGrid/LayoutSimpleGrid';
import Navigate from './components/Navigate/Navigate';
import ProductIntro from './components/ProductIntro/ProductIntro';

import { setRoute, ROUTE } from '../../store/actions/router';

const styles = theme => ({
  navigate: {
    marginTop: theme.spacing(5),
    width: '100%',
  },
  productIntro: {
    marginTop: '10vh',
    width: '100%',
  }
});

class Intro extends Component {
  render() {
    const { classes, setLoginView, setRegisterView } = this.props;
    return (
      <LayoutSimpleGrid>
        <Grid item sm={5} xs={8} className={classes.navigate}>
          <Navigate
            login={setLoginView}
            register={setRegisterView} />
        </Grid>
        {/* <Grid item sm={5} xs={8} className={classes.langSelect}>
          <LanguageSelect />
        </Grid> */}
        <Grid item sm={5} xs={8} className={classes.productIntro}>
          <ProductIntro />
        </Grid>
      </LayoutSimpleGrid>
    )
  }
}

Intro.propTypes = {
  classes: PropTypes.object.isRequired,
  setLoginView: PropTypes.func.isRequired,
  setRegisterView: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    setLoginView: () => dispatch(setRoute(ROUTE.LOGIN)),
    setRegisterView: () => dispatch(setRoute(ROUTE.REGISTRATION)),
  }
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(Intro));

import * as actionTypes from './actionTypes';

export const ROUTE = Object.freeze({
  HOME:        "HOME",
  LOGIN:        "LOGIN",
  REGISTRATION: "REGISTRATION",
  RESET_PASSWORD: "RESET_PASSWORD",
  VERIFICATION: "VERIFICATION",
});

export const setRoute = ( value ) => {
    return {
        type: actionTypes.SET_ROUTE,
        val: value
    };
};
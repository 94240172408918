const config = {
  BUILD_TS: process.env.REACT_APP_BUILD_TS,
  BUILD_SOURCE: process.env.REACT_APP_BUILD_SOURCE,
  GRAPHQL_ENDPOINT: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  AWS_AUTH_TYPE: process.env.REACT_APP_AWS_AUTH_TYPE,
  COGNITO_USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  COGNITO_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
  COGNITO_IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  COGNITO_LOGIN_PROVIDER: process.env.REACT_APP_COGNITO_LOGIN_PROVIDER,
  ALWAYS_CALLBACK: process.env.REACT_APP_ALWAYS_CALLBACK,
  MY_LIXANI_URL: process.env.REACT_APP_MY_LIXANI_URL,
}

export default config

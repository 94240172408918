import * as actionTypes from '../actions/actionTypes';

const initialState = {
  notificationFunction: null,
};

const setNotificationFunction = (state, action) => {
  return { ...state, notificationFunction: action.val };
}

const addNotification = (state, action) => {
  if (state.notificationFunction) {
    state.notificationFunction(action.val);
  }
  return { ...state };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_NOTIFICATION: return addNotification(state, action);
    case actionTypes.SET_NOTIFICATION_FUNCTION: return setNotificationFunction(state, action);
    default: return state;
  }
};

export default reducer;
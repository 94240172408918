import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from './components/Snackbar';
import { setNotificationFunction } from '../../store/actions/notification';

class Notification extends Component {
  state = {
    open: false,
    message: '',
  };

  addNotification = notif => {
    this.setState({
      open: true,
      message: notif.message,
    });
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };

  componentDidMount = () => {
    this.props.setNotificationFunction(this.addNotification);
  }

  componentWillUnmount = () => {
    this.props.setNotificationFunction(null);
  }

  render = () => {
    return (
      <div>
        <Snackbar
          open={this.state.open}
          message={this.state.message}
          onClose={this.handleClose} />
      </div>
    );
  }
}

Notification.propTypes = {
  setNotificationFunction: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    setNotificationFunction: (func) => dispatch(setNotificationFunction(func)),
  }
};

export default connect(null, mapDispatchToProps)(Notification);
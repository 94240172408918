import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import LayoutSimpleGrid from 'components/Layout/LayoutSimpleGrid/LayoutSimpleGrid';
import VerificationLogic from './VerificationLogic';

const styles = theme => ({
  verification: {
    marginTop: theme.spacing(5),
    width: '100%',
  },
});

class Verification extends Component {
  render() {
    const { classes } = this.props;
    return (
      <LayoutSimpleGrid>
        <Grid item sm={5} xs={8} className={classes.verification}>
          <VerificationLogic />
        </Grid>
      </LayoutSimpleGrid>
    )
  }
}

Verification.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Verification);

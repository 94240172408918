import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonBase from '@material-ui/core/ButtonBase';
import PhoneInput from 'react-phone-input-2';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import 'react-phone-input-2/dist/style.css'

const STATUS_HEIGHT = 17;

const styles = theme => ({
  root: {
  },
  inputContainer: {
    position: 'relative'
  },
  input: {
    height: 45,
    width: '100%',
    padding: 0,
    border: 0,
    background: theme.palette.primary.main,
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontSize: '1em',
    '&::placeholder': {
      color: theme.palette.text.primary,
			opacity:0.4,
      textAlign: 'center',
    },
    borderRadius: 0,
  },
  passwordToggleButton: {
    background: theme.palette.primary.main,
    color: theme.palette.text.primary,
    position: 'absolute',
    right: 1,
    paddingRight: 8,
    paddingLeft: 8,
    top:'50%',
    transform:'translateY(-50%)',
    fontSize: '0.7em',
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  phoneDropdown: {
    background: theme.palette.primary.main,
    border: 0,
  },
  fullWidth: {
    width: '100%',
  },
  status: {
    width: '100%',
    minHeight: STATUS_HEIGHT,
    fontSize: '0.75em',
    textAlign: 'center',
  },
  error: {
    color: 'red',
  },
});

function Input(props) {
  const [inputFocused, setInputFocus] = useState(false);
  const [stateValue, setValue] = useState(props.value || '');
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation('common');
  const {
    classes,
    className: classNameProp,
    error,
    fullWidth,
    onChange,
    theme,
    type,
    value,
    ...other
  } = props;

  useEffect(() => {
    setValue(value)
  }, [value])

  const labelText = () => {
    return `${props.label}${props.required ? ' *' : ''}`;
  }

  const statusText = () => {
    if (props.error && props.helpertext) {
      return props.helpertext;
    }
    if (inputFocused || stateValue || props.type === 'phone') {
      return labelText();
    }
    return '';
  }

  const internalChange = onChange => event => {
    try {
      setValue(event.target.value);
      onChange(event);      
    } catch (error) {
      setValue(event);
      onChange(event);
    }
  }

  const classNameRoot = clsx(
    classes.root,
    fullWidth && classes.fullWidth,
    classNameProp.root || classNameProp,
  );

  const classNameStatus = clsx(
    classes.status,
    error && classes.error
  );

  const classNameInput = clsx(
    classes.input,
    Boolean(classNameProp.input) && classNameProp.input,
    type === 'password' && classes.passwordinput
  );

  let finalType=type;
  if (type === 'password') {
    finalType = showPassword ? 'text' : 'password'
  }

  if (type === "phone") {
    return (
      <div className={classNameRoot}>
        <Typography variant="body2" className={classNameStatus}>
          {statusText()}
        </Typography>
        <PhoneInput
          autoFormat={true}
          defaultCountry={'fi'}
          onChange={internalChange(onChange)}
          value={value}
          placeholder={!inputFocused ? labelText() : ''}
          inputStyle={{...styles(theme).input, ...styles(theme)[classNameProp.input]}}
          inputExtraProps={{
            onFocus: () => setInputFocus(true),
            onBlur: () => setInputFocus(false)
          }}
          buttonStyle={styles(theme).phoneDropdown}
        />
      </div>
    );
  }
    
  return (
    <div className={classNameRoot}>
      <Typography variant="body2" className={classNameStatus}>
        {statusText(props)}
      </Typography>
      <div className={classes.inputContainer}>
        <input
          className={classNameInput}
          type={finalType}
          value={value}
          onChange={internalChange(onChange)}
          placeholder={!inputFocused ? labelText() : ''}
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
          {...other} />
        {type === 'password' &&
          <ButtonBase 
            onClick={() => setShowPassword(!showPassword)}
            className={classes.passwordToggleButton}
          >
            {showPassword ? <div>{t('auth.hide_password')}</div> : <div>{t('auth.show_password')}</div> }
          </ButtonBase>
        }
      </div>
    </div>
  );
}

Input.defaultProps = {
  className: {},
}

Input.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  label: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helpertext: PropTypes.string,
  value: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  theme: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(Input);

import * as actionTypes from '../actions/actionTypes';

const initialState = {
  email: '',
  phone: '',
  isPhoneAccount: true,
};

const setEmail = (state, action) => {
  return { ...state, email: action.val };
};

const setPhone = (state, action) => {
  return { ...state, phone: action.val };
};

const setIsPhoneAccount = (state, action) => {
  return { ...state, isPhoneAccount: action.val };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMAIL: return setEmail(state, action);
    case actionTypes.SET_PHONE: return setPhone(state, action);
    case actionTypes.SET_IS_PHONE_ACCOUNT: return setIsPhoneAccount(state, action);
    default: return state;
  }
};

export default reducer;

import React, { useState } from 'react';
import Config from 'Config'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { useTranslation } from 'react-i18next';

import VerificationForm from './components/VerificationForm/VerificationForm';

import { addNotification } from 'store/actions/notification';
import { setRoute, ROUTE } from 'store/actions/router';

function VerificationLogic(props) {
  const [verificationCode, setVerificationCode] = useState('');
  const { t } = useTranslation('common');

  const onVerificationCodeChanged = event => {
    setVerificationCode(event.target.value);
  }

  const verify = () => {
		console.log("PROPS",props);
    const validatePhone = Boolean(props.isPhoneAccount);
    const userData = {
      Username: validatePhone ? props.phone : props.email,
      Pool: new CognitoUserPool({
        UserPoolId: Config.COGNITO_USER_POOL_ID,
        ClientId: Config.COGNITO_CLIENT_ID
      }),
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmRegistration(verificationCode, true, function (err, result) {
      if (err) {
        alert(err);
        return;
      }
      console.log('verify result: ' + result);
      props.addNotification({ message: t("verification.verification_success") });
      props.setLoginView();
    });
  };

  return (
    <VerificationForm
      verificationCode={verificationCode}
      onVerificationCodeChanged={onVerificationCodeChanged}
      verify={verify} />
  )
}

VerificationLogic.propTypes = {
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  isPhoneAccount: PropTypes.bool.isRequired,
  setLoginView: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    email: state.user.email,
    phone: state.user.phone,
    isPhoneAccount: state.user.isPhoneAccount,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setLoginView: () => dispatch(setRoute(ROUTE.LOGIN)),
    addNotification: (notif) => dispatch(addNotification(notif)),
  }
};

const VerificationLogicConnect = connect(mapStateToProps, mapDispatchToProps)(VerificationLogic);

export default VerificationLogicConnect;
export { VerificationLogic };

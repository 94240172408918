import { createMuiTheme } from '@material-ui/core/styles';
import { baseTheme, pantonFonts } from './common';
import { BackgroundDarkJpg } from 'lixani-lib-data';

const defaultTextColor = 'rgba(255,255,255,1)';
const defaultBackgroundColor = '#147ba4';
const pantonCommon = {
	color: defaultTextColor,
	fontSize: '1rem',
	lineHeight: 1,
};
const textColors = {
	primary: 'rgba(255,255,255,1)',
	secondary: 'rgba(255,255,255,0.87)',
	tertiary: 'rgba(255,255,255,0.75)',
	caption: 'rgba(255,255,255,0.54)',
	disabled: 'rgba(255,255,255,0.38)',
	hint: 'rgba(255,255,255,0.38)',
};
const textWhiteColors = {
	primary: 'rgba(255,255,255,1)',
	secondary: 'rgba(255,255,255,0.87)',
	tertiary: 'rgba(255,255,255,0.75)',
	caption: 'rgba(255,255,255,0.54)',
	disabled: 'rgba(255,255,255,0.38)',
	hint: 'rgba(255,255,255,0.38)',
};
const textBlackColors = {
	primary: 'rgba(0,0,0,1)',
	secondary: 'rgba(0,0,0,0.87)',
	tertiary: 'rgba(0,0,0,0.75)',
	caption: 'rgba(0,0,0,0.54)',
	disabled: 'rgba(0,0,0,0.38)',
	hint: 'rgba(0,0,0,0.38)',
};
const scheme = {
	main: '#147ba4',
	darker: '#0b7197',
	dark: '#005a7f',
	lighter: '#1687af',
	light: '#1687af',
	lightest: '#21a8e0'
};

const darkTheme = createMuiTheme({
	typography: {
		fontFamily: pantonFonts.panton,
		fontPanton: {
			...pantonCommon,
			fontFamily: pantonFonts.panton,
		},
		fontPantonLight: {
			...pantonCommon,
			fontFamily: pantonFonts.pantonLight,
		},
		fontPantonExtraLight: {
			...pantonCommon,
			fontFamily: pantonFonts.pantonExtraLight,
		},
		fontPantonSemiBold: {
			...pantonCommon,
			fontFamily: pantonFonts.pantonSemiBold,
		},
		h4: {
			color: textColors.secondary,
			fontFamily: pantonFonts.panton,
		},
		h5: {
			color: textColors.secondary,
			fontFamily: pantonFonts.panton,
		},
		body1: {
			color: textColors.secondary,
			fontFamily: pantonFonts.panton,
		},
		body2: {
			color: textColors.secondary,
			fontFamily: pantonFonts.panton,
			fontSize: '0.875rem',
		},
		tertiary: {
			color: textColors.tertiary,
			fontFamily: pantonFonts.pantonLight,
		},
		caption: {
			color: textColors.caption,
			fontFamily: pantonFonts.pantonLight,
		},
	},
	palette: {
		borderColor: scheme.light,
		type: 'dark',
		text: {
			...textColors,
		},
		textBlack: {
			...textBlackColors,
		},
		textWhite: {
			...textWhiteColors,
		},
		textInvert: {
			...textBlackColors,
		},
    primary: {
      main: scheme.lighter,
      light: scheme.lightest,
      dark: scheme.dark,
      gradient: 'linear-gradient(45deg, #1687af, #5bb7e1)',
		},
    danger: {
      main: '#b0340a',
      light: '#e96538',
      dark: '#7a0000',
		},
    trash: {
      main: "rgba(176, 52, 10,0.5)",
      light: '#e96538',
      dark: '#7a0000',
		},
		buttonPrimary: scheme.lighter,
		buttonSecondary: scheme.lightest,
		buttonMain: scheme.main,
		buttonDark: scheme.dark,
		buttonDarker: scheme.darker,
		buttonLighter: scheme.lighter,
		buttonLight: scheme.light,
		buttonLightest: scheme.lightest,
	},
	component: {
		appbarToolbarRoot: {
			backgroundColor: scheme.lighter,
		},
		border: 'none',
		borderMust: `1px solid ${scheme.darker}`,
		borderDotted: `1px dotted ${scheme.dark}`,
		dialogPaper: {
			background: defaultBackgroundColor,
			position: 'initial',
			overflow: 'initial',
		},
		dialogPaperFullscreen: {
			background: scheme.main,
		},
		dialogHeader: {
			background: 'linear-gradient(45deg, #1687af, #5bb7e1)',
		},
		dialogHeaderCloseButton: {
			borderLeft: `1px solid ${scheme.lighter}`,
			padding: baseTheme.spacing(1),
		},
	},
  overrides: {
    MuiCssBaseline: {
      '@global': {
				html: {
					background: `url("data:image/jpg;base64,${BackgroundDarkJpg}") no-repeat center center fixed`,
					backgroundSize: 'cover',
					color: defaultTextColor,
					fontSmoothing: 'initial',
				},
				body: {
					background: 'none',
					backgroundColor: 'initial',
					color: defaultTextColor,
				},
      },
		},
		MuiAppBar: {
			colorPrimary: {
				backgroundColor: scheme.lighter,
			}
		},
    MuiButton: {
      root: {
        borderRadius: '0',
      }
    },
    MuiOutlinedInput: {
        root: {
            position: 'relative',
            '& $notchedOutline': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
            },
            '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                borderColor: '#85aede',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                },
            },
            '&$focused $notchedOutline': {
                borderColor: '#85aede',
                borderWidth: 2,
            },
        },
    },
    MuiFormLabel: {
			root: {
				'&$focused': {
					color: '#85aede'
				}
			}
		},
		MuiMenuItem: {
			root: {
				fontSize: '0.87rem',
				minHeight: baseTheme.spacing(4)
			}
		}
  },
});

export default darkTheme;
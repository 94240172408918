import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const LayoutSimpleGrid = (props) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={0}>
        {props.children}
      </Grid>
    </div>
  );
};

const styles = () => ({
  root: {
    flexGrow: 1,
  },
});

LayoutSimpleGrid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired
  ]),
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(LayoutSimpleGrid);
import React, { useState } from 'react';
import Config from 'Config';
import PropTypes from 'prop-types';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ConfirmPassword from './components/ForgotPassword/ConfirmPassword';

import { passwordHelper } from 'util/password';

import { addNotification } from '../../store/actions/notification';
import { setRoute, ROUTE } from '../../store/actions/router';

function ResetPasswordLogic(props) {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [stateError, setError] = useState(null);
  const [username, setUsername] = useState('');
  const [verificationData, setVerificationData] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const { t } = useTranslation('common');

  const recoverPassword = () => {
    setError(null);
    const userData = {
      Username: username.toLowerCase(),
      Pool: new CognitoUserPool({
        UserPoolId: Config.COGNITO_USER_POOL_ID,
        ClientId: Config.COGNITO_CLIENT_ID
      }),
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: () => { // (data)
        props.addNotification({ message: t("password.password_recovered") });
        props.setLoginView();
      },
      onFailure: (error) => {
        console.error("Recover error", JSON.stringify(error));
        if (error.code === "UserNotFoundException") {
          setError(t("error.username_does_not_exist"));
        } else if (error.code === "LimitExceededException") {
          setError(t("error.attempt_limit_exceeded"));
        } else {
          setError(error.message);
        }
      },
      inputVerificationCode: (data) => {
        console.log("Got inputVerificationCode with data", JSON.stringify(data), data);
        setVerificationData({
          medium: data.CodeDeliveryDetails && data.CodeDeliveryDetails.DeliveryMedium,
          destination: data.CodeDeliveryDetails && data.CodeDeliveryDetails.Destination,
        });
      }
    });
  }

  const confirmRecovery = async () => {
    setError(null);
    const userData = {
      Username: username.toLowerCase(),
      Pool: new CognitoUserPool({
        UserPoolId: Config.COGNITO_USER_POOL_ID,
        ClientId: Config.COGNITO_CLIENT_ID
      }),
    };
    const cognitoUser = new CognitoUser(userData);

    try {
      await new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(verificationCode, password, {
          onSuccess: () => resolve(),
          onFailure: (error) => reject(error)
        });
      });
    } catch (error) {
      console.error("Confirm error", JSON.stringify(error));
      if (error.code === "CodeMismatchException") {
        setError(t("error.wrong_confirmation_code"));
      } else {
        setError(error.message);
      }
      return;
    }

    props.addNotification({ message: t("password.password_recovered") });
    props.setLoginView();
  }

  const handleChange = name => event => {
    let value=''
    try {
      value = event.target.value;
    } catch (error) {
      value = event;
    }
    value=value.replace(/ /g,"");
    console.log(name)
    if (name === 'password') {
      setPassword(value);
      setPasswordError(passwordHelper(t)(value) !== null);
    } else if (name === 'username') {
      setUsername(value);
    } else if (name === 'verificationCode') {
      setVerificationCode(value);
    }
  };
  
  const confirmEnabled = Boolean(verificationCode && password && !passwordError);

  return (verificationData === null ?
    <ForgotPassword
      dataChanged={handleChange}
      recoverPassword={recoverPassword}
      error={stateError}
      username={username} />
    :
    <ConfirmPassword
      dataChanged={handleChange}
      confirmRecovery={confirmRecovery}
      verificationData={verificationData}
      passwordHelper={passwordHelper(t)}
      password={password}
      passwordError={passwordError}
      error={stateError}
      confirmEnabled={confirmEnabled} />
  );
}

ResetPasswordLogic.propTypes = {
  addNotification: PropTypes.func.isRequired,
  setLoginView: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    addNotification: (notif) => dispatch(addNotification(notif)),
    setLoginView: () => dispatch(setRoute(ROUTE.LOGIN)),
  }
};

export default connect(null, mapDispatchToProps)(ResetPasswordLogic);

import * as actionTypes from './actionTypes';

export const addNotification = (value) => {
  return {
    type: actionTypes.ADD_NOTIFICATION,
    val: value
  };
};

export const setNotificationFunction = (value) => {
  return {
    type: actionTypes.SET_NOTIFICATION_FUNCTION,
    val: value
  };
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setRoute, ROUTE } from '../../store/actions/router';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import LayoutSimpleGrid from '../../components/Layout/LayoutSimpleGrid/LayoutSimpleGrid';
import ResetPasswordLogic from './ResetPasswordLogic';
import ResetPasswordNavigate from './components/ResetPasswordNavigate/ResetPasswordNavigate';

const styles = theme => ({
  resetPassword: {
    marginTop: theme.spacing(5),
    width: '100%',
  },
  navigate: {
    marginTop: theme.spacing(5),
    width: '100%',
  },
});

class ResetPassword extends Component {
  render() {
    const { classes, setHomeView } = this.props;
    return (
      <LayoutSimpleGrid>
        <Grid item sm={5} xs={8} className={classes.resetPassword}>
          <ResetPasswordLogic />
        </Grid>
        <Grid item sm={5} xs={8} className={classes.navigate}>
          <ResetPasswordNavigate
            home={setHomeView}
          />
        </Grid>
      </LayoutSimpleGrid>
    )
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  setHomeView: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    setHomeView: () => dispatch(setRoute(ROUTE.HOME)),
  }
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(ResetPassword));
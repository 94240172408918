import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Input from 'components/Input/Input';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  headline: {
    marginBottom: theme.spacing(4),
  },
  instructions: {
    marginBottom: theme.spacing(2),
    opacity: 0.8,
  },
  textField: {
    height: 60,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    width: '100%',
    height: 42,
  },
});

function VerificationForm(props) {
  const { classes, verificationCode, onVerificationCodeChanged, verify } = props;
  const { t } = useTranslation('common');

  return (
    <div>
      <Typography variant="h5" className={classes.headline}>
        {t('verification.registration_complete')}
      </Typography>
      <Typography variant="body1" className={classes.instructions}>
        {t('verification.verification_instructions')}
      </Typography>
      <Input value={verificationCode}
        onChange={onVerificationCodeChanged}
        fullWidth={true}
        className={{ input: classes.textField }}
        label={t('verification.verification_code')} />
      <Button
        onClick={verify}
        variant="contained" color="secondary"
        className={classes.button}>
        {t('verification.verify')}
      </Button>
    </div>
  )
}

VerificationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  verificationCode: PropTypes.string.isRequired,
  onVerificationCodeChanged: PropTypes.func.isRequired,
  verify: PropTypes.func.isRequired,
};

export default withStyles(styles)(VerificationForm)
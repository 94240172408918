import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import { useTranslation } from 'react-i18next';

import ButtonBase from '@material-ui/core/ButtonBase';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

import getTextWidth from 'util/getTextWidth';

const useStyles = makeStyles((theme) => ({
	root: {},
	input: {
		'&::-webkit-calendar-picker-indicator': {
			filter: 'invert(1)',
		}
	},
	inputLabel: {
		...theme.typography.fontPanton,
		color: theme.palette.text.secondary
	},
	inputLabelShrink: {
		transform: 'translate(12px, -4px) scale(0.75) !important'
	},
	togglePasswordButton: {
		fontSize: '0.75rem',
		padding: theme.spacing(1),
	},
	phoneInput: {
		color: theme.palette.textInvert.secondary,
		'& .form-control': {
			...theme.typography.fontPanton,
			color: theme.palette.text.secondary,
			background: 'none',
			border: 'none',
			paddingBottom: theme.spacing(1.33),
			paddingTop: theme.spacing(1.33),
			'&:focus': {
				border: 'none',
				boxShadow: 'none',
			},
		},
		'& .special-label': {
			...theme.typography.fontPantonLight,
			background: 'none',
			fontSize: '0.8rem',
			left: theme.spacing(1.25),
			top: -theme.spacing(1.5),
		},
	},
	phoneInputFullWidth: {
		width: '100%',
		'& .form-control': {
			width: '100%',
		}
	},
	phoneInputContainer: {
		borderRadius: theme.spacing(0.5),
		position: 'relative',
	},
	phoneInputFieldset: {
		borderColor: 'rgba(255,255,255,0.5)',
		borderRadius: 'inherit',
		borderStyle: 'solid',
		borderWidth: 1,
		bottom: 0,
		left: 0,
		margin: 0,
		paddingBottom: 0,
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingTop: 0,
		pointerEvents: 'none',
		position: 'absolute',
		right: 0,
		top: -5,
	},
	phoneInputFieldsetActive: {
		borderColor: theme.palette.primary.light,
		borderWidth: 2,
	},
	phoneInputLabel: {
		...theme.typography.fontPantonLight,
		fontSize: '0.8rem',
	},
	phoneInputLabelActive: {
		color: theme.palette.primary.light,
	},
	phoneInputMarginDense: {
		marginBottom: theme.spacing(0.5),
		marginTop: theme.spacing(1),
	},
	phoneInputMarginNormal: {
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(2),
	}
}));


const TextInput = React.forwardRef((props, ref) => {
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState(false);
	const { t } = useTranslation('common');
	const {
		children,
		className,
		disabled,
		error,
		focused,
		fullWidth,
		helperText,
		id,
		label,
		labelId,
		InputLabelProps,
		inputProps,
		InputProps,
		margin,
		required,
		select,
		shrink,
		SelectProps,
		type,
		...restProps
	} = props;

	const labelWidthValue = getTextWidth(label) * 1.12;

	const inputUuid = uuidv4();
	const labelUuid = uuidv4();

	return (
		<FormControl
			className={className}
			disabled={disabled}
			error={error}
			focused={focused}
			fullWidth={fullWidth}
			margin={margin}
			required={required}
			size="small"
			variant="outlined"
		>
			<InputLabel
				classes={{
					root: classes.inputLabel,
					shrink: classes.inputLabelShrink,
				}}
				htmlFor={id ? id : inputUuid}
				id={labelId ? labelId : labelUuid}
				shrink={shrink}
				{...InputLabelProps}
			>
				{label}
			</InputLabel>
			{select ?
				<Select
					id={id ? id : inputUuid}
					labelId={labelId ? labelId : labelUuid}
					labelWidth={labelWidthValue}
					notched={shrink}
					ref={ref}
					inputProps={{
						onBlur: props.onBlur,
						onClick: props.onClick,
						onFocus: props.onFocus,
						...inputProps
					}}
					{...SelectProps}
					{...restProps}
				>
					{children}
				</Select>
			:
				<OutlinedInput
					classes={{ input: classes.input	}}
					id={id ? id : inputUuid}
					type={type === "password" && !showPassword ? 'password' : type !== "password" ? type : 'text'}
					endAdornment={type === "password" &&
						<InputAdornment position="end">
							<ButtonBase
								className={classes.togglePasswordButton}
								aria-label="toggle password visibility"
								onClick={() => setShowPassword(!showPassword)}
								onMouseDown={(e) => {e.preventDefault()}}
								edge="end"
							>
								{showPassword ? t('auth.hide_password') : t('auth.show_password')}
							</ButtonBase>
						</InputAdornment>
					}
					ref={ref}
					labelWidth={labelWidthValue}
					inputProps={{
						onBlur: props.onBlur,
						onClick: props.onClick,
						onFocus: props.onFocus,
						...inputProps
					}}
					notched={shrink}
					{...InputProps}
					{...restProps}
				/>
			}
			{helperText &&
				<FormHelperText>{helperText}</FormHelperText>
			}
		</FormControl>
	)
})

TextInput.displayName = 'TextInput';

TextInput.defaultProps = {
	label: '',
}

TextInput.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	error: PropTypes.bool,
	focused: PropTypes.bool,
	fullWidth: PropTypes.bool,
	helperText: PropTypes.string,
	id: PropTypes.string,
	InputLabelProps: PropTypes.object,
	inputProps: PropTypes.object,
	InputProps: PropTypes.object,
	label: PropTypes.string,
	labelId: PropTypes.string,
	margin: PropTypes.string,
	onBlur: PropTypes.func,
	onClick: PropTypes.func,
	onFocus: PropTypes.func,
	required: PropTypes.bool,
	select: PropTypes.bool,
	SelectProps: PropTypes.object,
	shrink: PropTypes.bool,
	type: PropTypes.string,
}

export default TextInput;
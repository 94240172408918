import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';

import Layout from 'components/Layout/Layout';
import Notification from './Notification/Notification';
import Router from 'components/Router/Router';

import classicTheme from 'components/theme';

import { setRoute, ROUTE } from 'store/actions/router';


class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={classicTheme}>
        <CssBaseline />
        <Layout
          routeHome={this.props.setHomeView}>
          <Notification />
          <Router />
        </Layout>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  setHomeView: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    setHomeView: () => dispatch(setRoute(ROUTE.HOME)),
  }
};

export default connect(null, mapDispatchToProps)(App);

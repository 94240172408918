import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Input from 'components/Input/Input';
import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  header: {
    marginBottom: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
  },
  textField: {
    height: 60,
  },
  button: {
    marginTop: theme.spacing(2),
    width: '100%',
    height: 42,
  },
  error: {
    minHeight: theme.spacing(2),
    color: 'red',
    textAlign: 'center',
  },
});

function ForgotPassword(props) {
  const { classes, error } = props;
  const { t } = useTranslation('common');

  return (
    <form noValidate autoComplete="on" className={classes.form}>
      <Typography variant="h5" className={classes.header}>
        {t('password.recover_password')}
      </Typography>
      <Typography variant="body1" className={classes.error}>
        {error ? error : ''}
      </Typography>
      <Input
        onChange={props.dataChanged('username')}
        value={props.username}
        required
        fullWidth={true}
        type="phone"
        className={{ input: "highInput"}}
        margin="normal"
        label={t('login.username')} />
      <Button
        onClick={props.recoverPassword}
        variant="contained" color="primary"
        className={classes.button}>
        {t('password.recover')}
      </Button>
    </form>
  )
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  dataChanged: PropTypes.func.isRequired,
  recoverPassword: PropTypes.func.isRequired,
  error: PropTypes.string,
  username: PropTypes.string,
};

export default withStyles(styles)(ForgotPassword);
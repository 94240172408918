import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  button: {
    marginTop: theme.spacing(1 / 2.5),
    marginBottom: theme.spacing(1 / 2.5),
    width: '100%',
    height: 42,
  },
});

function Navigate(props) {
  const { classes, home } = props;
  const { t } = useTranslation('common');

  return (
    <div>
      <Button
        onClick={home}
        variant="contained" color="primary"
        data-testid="home"
        className={classes.button}>
        {t('auth.back')}
      </Button>
    </div>
  )
}

Navigate.propTypes = {
  classes: PropTypes.object.isRequired,
  home: PropTypes.func.isRequired,
};

export default withStyles(styles)(Navigate)
import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		marginLeft: theme.spacing(1.5),
		marginRight: theme.spacing(1.5),
	}
}));


function ButtonLoading(props) {
	const classes = useStyles();

	return (
		<span className={classes.root}>
			<CircularProgress
				color="inherit"
				size={16}
				{...props}
			/>
		</span>
	)
}

export default ButtonLoading;